<template>
  <!-- 收款登记列表页 -->
  <div class="table-responsive mt-5">
    <div class="d-flex align-items-center">
      <!-- 左边 -->
      <router-link :to="{ name: 'incomerecord_create' }">
        <el-button type="success" size="mini">收款登记</el-button>
      </router-link>

      <!-- 右边 -->
      <div class="ml-auto">
        <el-input
          v-model="formdata.name"
          placheholder="要搜索的供应商名称"
          size="mini"
          style="width: 150px"
          class="mr-2 ml-3"
        ></el-input>
        <el-button type="info" size="mini">搜索</el-button>
      </div>
    </div>

    <el-table
      border
      class="mt-3"
      :data="tableData.data"
      style="width: 100%; height: 100%"
      :show-summary="true"
      :summary-method="summaryMethod"
    >
      <el-table-column label="操作" width="200" align="center">
        <template #default="scope">
          <el-button-group>
            <el-button size="small" plain @click="updatespayments(scope.row.id)"
              >修改</el-button
            >
            <el-button size="small" plain @click="pdfview(scope.row.id)"
              >查看</el-button
            >
            <el-button size="small" plain type="danger">删除</el-button>
          </el-button-group>
        </template>
      </el-table-column>
      <el-table-column prop="in_date" label="日期" width="100" align="center" />
      <el-table-column
        prop="text_no"
        label="凭证编号"
        width="140"
        align="center"
      />
      <el-table-column
        prop="fk_company"
        label="支付单位"
        width="240"
        align="center"
      />
      <el-table-column
        prop="in_amount"
        label="到账金额"
        width="180"
        align="center"
        :formatter="toThousands"
      />
      <el-table-column
        prop="in_contract_id"
        label="对应合同"
        width="180"
        align="center"
      />
      <el-table-column
        prop="des_text"
        label="付款说明"
        width="180"
        align="center"
      />
    </el-table>
  </div>
  <!-- 查看PDF文件List -->
  <el-dialog v-model="pdfurlVisible" width="100%" fullscreen>
    <!--fileclass分类ID     0-项目图片  1-付款合同  2-付款申请   3-发票   
   4-银行回单  5-收款合同  6-收款凭证 -->
    <pdf-view fileclass="6" :prdclassid="prdclassid"></pdf-view>
  </el-dialog>
  <div style="height: 60px"></div>
  <!-- 占位底部60PX -->
  <el-footer
    class="border-top d-flex align-items-center px-0 position-fixed bg-white"
    style="bottom: 0; left: 150px; right: 0; z-index: 100"
  >
    <el-config-provider :locale="locale">
      <el-pagination
        v-model:currentPage="currentPage"
        v-model:page-size="pageSize"
        :page-sizes="[5, 10, 20, 100, 300]"
        layout="sizes, prev, pager, next,total,jumper"
        :total="lotalrows"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        prev-text="上一页"
        next-text="下一页"
        background
      >
      </el-pagination
    ></el-config-provider>
  </el-footer>
</template>

<script>
import axios from "axios";
import zhCnelementpage from "element-plus/lib/locale/lang/zh-cn";
export default {
  data() {
    return {
      //分页数据
      pageSize: 10, //每页数据 默认10条
      currentPage: 1, //当前页码  默认第1页
      lotalrows: 0, //总行数
      locale: zhCnelementpage, //中文分页
      //

      prdclassid: "", //查看附件记录的ID
      pdfurlVisible: false, //查看附件列表开关
      //
      project_id: window.sessionStorage.getItem("project_ids"),
      //
      formdata: {
        name: "",
      },
      //
      tableData: {
        data: [],
      },
    };
  },

  created() {
    this.loadtabledata(this.pageSize, this.currentPage);
  },
  methods: {
    //选择页码选项
    handleCurrentChange(val) {
      console.log("选择页码选项", val);
      this.currentPage = val;
      this.loadtabledata(this.pageSize, this.currentPage);
    },
    //选择每页条数
    handleSizeChange(val) {
      console.log("选择每页条数", val);
      this.pageSize = val;
      this.loadtabledata(this.pageSize, this.currentPage);
    },
    loadtabledata(rows, pags) {
      axios
        .get("admin/IncomeRecord", {
          params: {
            project_id: this.project_id,
            page: pags,
            list_rows: rows,
          },
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
        })
        .then((response) => {
          console.log("******", response.data, "******");
          this.currentPage = response.data.data.current_page; //当前页码  默认第1页
          this.lotalrows = response.data.data.total; //总行数
          this.tableData.data = response.data.data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          // 总是会执行
        });
    },
    //查看pdf清单列表
    pdfview(vals) {
      console.log("*********", vals);
      this.prdclassid = vals;
      this.pdfurlVisible = true;
    },
    //打开修改页面
    updatespayments(rowid) {
      window.sessionStorage.setItem("rowid", rowid);
      // this.$router.push({ name: "payments_spayments_update" });
    },
    //格式化数字
    toThousands(cellValue) {
      return cellValue.in_amount.toLocaleString("zh", {
        style: "currency",
        currency: "CNY",
      });
    },
    //合计行
    summaryMethod(param) {
      let arr = [];
      let totalsum = 0;
      param.data.forEach((element) => {
        totalsum += element.in_amount;
      });

      arr[0] = "合计";
      arr[4] = totalsum.toLocaleString("zh", {
        style: "currency",
        currency: "CNY",
      });
      return arr;
    },
  },
};
</script>

<style></style>
